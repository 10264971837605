<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Purchasing Request - {{GroupingBy}}</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%;"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <div style="border: 1px solid lightgrey;padding:10px">
                        <CRow>
                            <CCol class="sm-1">
                                <CRow>
                                    <CCol class="sm-4">
                                        <label class="form-label font-weight">New</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-3">
                                        <label class="form-label font-weight">{{New}}</label>
                                    </CCol>
                                </CRow>
                                 <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Revisi</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Revisi}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                <CCol class="sm-1">
                                        <label class="form-label font-weight">Approved</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Approved}}</label>
                                </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Ready</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Ready}}</label>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Pending</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Pending}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Checking</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Checking}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Finish</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Checking}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Delete</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{Delete}}</label>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        </div>
                    </CCol>
                </CRow>

                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                        &nbsp;
                        <button id="view" class="btn btn-secondary"
                            @click="generatePDF()">
                            <i class="fa fa-file-pdf-o"></i> PDF
                        </button>
                    </CCol>
                </CRow>
                <hr>

                <CRow>
                    <CCol>
                        <div class="col-xs-6 text-light pb-2">
                            <button id="ByRequest" class="btn btn-secondary" @click="iReqClick()">
                                By Request
                            </button>
                            &nbsp;
                            <button id="ByItem" class="btn btn-secondary" @click="iItemClick()">
                                By Item
                            </button>
                        </div>
                        <purchasing-report-grid 
                            ref = "purchasingReportGrid"
                            :key="gridReload" 
                            :dateTo="DateToChangest" 
                            :dateFrom="DateFromChangest" 
                            :group="group"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import purchasingReportGrid from './Grid/PurchasingRequestReportGrid.vue'
import purchasingReportService from './Script/PurchasingRequestReportService.js'
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { report, datetime_zone } from '../../../infrastructure/constant/variable';


export default {
    name: 'PurchasingReport',
    components: {
        'purchasing-report-grid': purchasingReportGrid,
        'date-picker': datePicker
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();
        this.onChangeDateType();
        
        var variables = {
            startDate: this.DateFrom + datetime_zone.date_from,
            endDate: this.DateTo + datetime_zone.date_to,
        };
        this.getQuantity(variables);
        
        this.GroupingBy = 'By Request';
        this.group = [{field:"request_number"}];
    },
    data(){
        return{
            gridReload: 0,
            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,
            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,
            New: '',
            Revisi: '',
            Approved: '',
            Ready: '',
            Checking: '',
            Finish: '',
            Delete: '',
            Pending: '',
            
            GroupingBy: '',
            group: [],
            DateTypeData: [],
            DateType: report.default_date,
        }
    },
    methods: {
        iReqClick() {
            this.GroupingBy = 'By Request';
            this.group = [{field:"request_number"}];
            this.gridReload++;
        },
        iItemClick() {
            this.GroupingBy = 'By Item';  
            this.group = [{field: "product_code"}];
            this.gridReload++;
        },
        async getQuantity(variables){
            var dataPurchasingReport= await purchasingReportService.getPurchasingRequestReport(variables);
            var summary =  dataPurchasingReport.summary.quantity_per_status;
            this.New = summary.New;
            this.Revisi = summary.Revisi;
            this.Approved = summary.Approved;
            this.Ready = summary.Ready;
            this.Checking = summary.Checking;
            this.Finish = summary.Finish;
            this.Delete = summary.Delete;
            this.Pending = summary.Pending;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;

            var variables = {
                startDate: this.DateFrom + datetime_zone.date_from,
                endDate: this.DateTo + datetime_zone.date_to,
            };
            await this.getQuantity(variables);
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;

                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;

                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables)
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.purchasingReportGrid.exportExcel();
        },
        generatePDF(){
            this.$refs.purchasingReportGrid.generatePDF();
        }
    }
}
</script>

<style scoped>

</style>